import React from "react";
import Container from "react-bootstrap/Container";

const About = () => (
  <Container>
    <h1>About</h1>
  </Container>
);

export default About;
